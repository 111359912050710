import React from 'react';
import { ButtonGroup } from '@iq/react-components';

const themeLayouts = [
  { type: 'LIGHT_MODE', icon: 'he-sun', tooltip: 'Light mode' },
  { type: 'DARK_MODE', icon: 'he-moon', tooltip: 'Dark mode' },
];

const ThemeSwitch = ({ nightmode, onChange, className }) => {
  const activeLayout = nightmode ? 'DARK_MODE' : 'LIGHT_MODE';

  return (
    <div className={`theme-swtich-component ${className || ''}`}>
      <div className="theme-switch">
        <ButtonGroup
          className="toggle-nightmode"
          layouts={themeLayouts}
          activeLayout={activeLayout}
          onClick={onChange}
        />
      </div>
    </div>
  );
};

export default ThemeSwitch;
